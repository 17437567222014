<template>
	<div class="welfare-main-con">
		<div class="welfare-nav-con">
			<div v-for="(item,index) in navList" :key="index" class="item" :class="{'active': navType==item.value}" @click="navType=item.value">{{ item.label }}</div>
		</div>
		<div class="welfare-scroll-con">
			<el-scrollbar style="height:100%;">
				<div class="welfare-vip-con" v-if="navType==1">
					<div class="welfare-vip-title">
						<img src="~@/assets/img/welfare-vip-title.png" alt="">
					</div>
					<div class="vip-table">
						<div class="vip-bar" :class="['level'+User.vip_level]">
							<img src="~@/assets/img/vip-current.png" alt="">
						</div>
						<div class="vip-tr">
							<div class="vip-td">VIP等级</div>
							<div class="vip-td">当前<br />等级</div>
							<div class="vip-td">充值金额</div>
							<div class="vip-td">升级红包</div>
							<div class="vip-td">充值加成</div>
						</div>
						<div class="vip-tr" v-for="(item,index) in VipList" :key="index">
							<div class="vip-td"><div class="vip-tip" :class="['level'+item.level]"></div></div>
							<div class="vip-td"></div>
							<div class="vip-td"><div class="coins"></div><span>{{item.threshold}}</span></div>
							<div class="vip-td">{{item.packet}}</div>
							<div class="vip-td">{{item.rebate}}%</div>
						</div>
					</div>
					<div class="vip-desc">所有充值红包系统自动发放</div>
				</div>
				<div class="welfare-vip-con" v-else>
					<div class="welfare-vip-title">
						<img src="~@/assets/img/welfare-vip-intro.png" alt="">
					</div>
					<div class="welfare-rule-con">
						<div class="content">
							<div class="scroll-con">
								<el-scrollbar style="height:100%;">
									<div class="word-content">
										<div class="vip-question">
											<div class="item">
												<div class="tilte">1.会员如何升级？</div>
												<div class="info">注册wanskins之后，绑定并校验您的Steam交易链接。达到一定的充值门槛，即可享受所对应的VIP等级的福利。</div>
											</div>
											<div class="item">
												<div class="tilte">2.是否可以一次上升多个等级？</div>
												<div class="info">wanskins支持VIP会员单次充值上升多个等级，即一次充值达到一定等级的，则自动升级至已覆盖的最高的会员等级。并且可以一次性获得已覆盖全部等级所对应的累计升级红包。</div>
											</div>
											<div class="item">
												<div class="tilte">3.升级红包只能领取1次吗？</div>
												<div class="info">是的，只可领取一次等级专属红包。红包随VIP升级自动发放到用户余额，请自行至账户收支明细中查看。
										
												</div>
											</div>
										
											<div class="item">
												<div class="tilte">4.在何处查看我的等级？</div>
												<div class="info">是的，只可领取一次等级专属红包。红包随VIP升级自动发放到用户余额，请自行至账户收支明细中查看。</div>
											</div>
											<div class="item">
												<div class="tilte">5.在何处查看我的等级？</div>
												<div class="info">用户登录wanskins之后，点击导航栏“VIP会员”可看到当前等级。</div>
											</div>
										</div>
									</div>
								</el-scrollbar>
							</div>
						</div>
					</div>
				</div>
			</el-scrollbar>
		</div>
	</div>
</template>

<script>
	import { Welfare } from '@/network/api.js'
	import * as $api from '@/network/api.js'
	import {
		mapState
	} from 'vuex'
	export default{
		name:'WelfareLevel',
		data(){
			return{
				navType: 1,
				navList: [
					{label: 'VIP福利',value: 1},
					{label: 'VIP等级说明',value: 2},
				],
				VipList: [],
      	Viptips: {},
			}
		},
		created() {
			
		},
		computed: {
			...mapState([
				'User',
			])
		},
		created() {

		},
		mounted() {
			this.init()
		},
		methods: {
			init(){
				$api.getVipLists().then(res => {
					this.VipList = res.data.data
				})
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.welfare-vip-title{
		height: 24px;
		@include HScenter;
		margin-top: 18px;
		img{
			height: 100%;
		}
	}
	.vip-table{
    width: 328px;
    margin: 18px auto;
    position: relative;
    .vip-bar{
      position: absolute;
      top: 43px;
      left: 89px;
      bottom: 0;
      width: 4px;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background: var(--logo, linear-gradient(-90deg, #448EFD 0%, #44FDE7 100%));
        opacity: 0.3;
        border-radius: 2px;
      }
      &::after{
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        background: var(--logo, linear-gradient(-90deg, #448EFD 0%, #44FDE7 100%));
        border-radius: 2px;
      }
      img{
        position: absolute;
        width: 16px;
        height: 16px;
        left: 50%;
        margin-left: -8px;
        z-index: 3;
      }
      @for $i from 1 to 11{
        &.level#{$i}{
          img{
            top: 13px+($i - 1)*43px;
          }
          &::after{
            height: 21px+($i - 1)*43px;
          }
        }
      }
      &.level10{
        &::after{
            height: 100%;
          }
      }
    }
    .vip-tr{
      display: flex;
      gap: 1px;
      margin-bottom: 1px;
      .vip-td{
        width: 72px;
        height: 42px;
        text-align: center;
        font-size: 12px;
        background: #2A2A38;
        @include HScenter;
        &:nth-child(2){
          width: 36px;
        }
				span{
					background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
					background-clip: text;
					-webkit-background-clip: text;
					-webkit-text-fill-color: transparent;
				}
        .coins{
          width: 14px;
          height: 14px;
          margin-right: 4px;
          background-image: url(~@/assets/img/coins-white1.svg);
        }
        .vip-tip{
          width: 48px;
          height: 20px;
          background-size: 100% 100%;
          @for $i from 1 to 11{
            &.level#{$i}{
              background-image: url('~@/assets/img/vip-level'+$i+'.png');
            }
          }
        }
      }
    }
  }
	.vip-desc{
		color: #C1C1C1;
		text-align: center;
		margin-bottom: 18px;
	}
	.vip-question{
		display: flex;
		flex-direction: column;
		gap: 12px;
		.item{
			.title{
				
			}
			.info{
				margin-top: 10px;
				font-size: 12px;
			}
		}
	}
</style>
