<template>
	<div class="welfare-main-con">
		<div class="welfare-rule-con" v-if="ruleShow">
			<div class="title">活动规则</div>
			<div class="content">
				<div class="scroll-con">
					<el-scrollbar style="height:100%;">
						<div class="word-content">{{ publicConfig.desc_activity_recharge }}</div>
					</el-scrollbar>
				</div>
			</div>
			<div class="welfare-btn"><img @click="ruleShow=false" src="~@/assets/img/welfare/return-btn-bg.png" alt=""></div>
		</div>
		<template v-else>
			<div class="welfare-nav-con">
				<div v-for="(item,index) in navList" v-show="item.show" :key="index" class="item" :class="{'active': navType==item.value}" @click="navType=item.value">{{ item.label }}</div>
			</div>
			<div class="welfare-scroll-con">
				<el-scrollbar style="height:100%;">
					<div class="welfare-recharge-con">
						<div class="total-con">
							<div class="rule-tit" @click="ruleShow=true">充值说明<img src="~@/assets/img/icon-help.png" alt=""></div>
							<div class="item">您的注册时间：{{ info.user.created_at }}</div>
							<div class="item">已注册天数：{{ info.user.reg_days }}（仅注册30天内用户参与）</div>
							<div class="item">
								<!-- 已充值天数：7 -->
								<div class="price">
									已累计领取
									<div class="coins"></div>
									<span>{{ info.act_data.bean_total }}</span>
								</div>
							</div>
						</div>
						<div class="list">
							<div class="item" v-for="(item,index) in currentList" :key="index">
								<div class="title">
									<div class="name">{{ item.title }}</div>
									<div class="num"><span>{{ item.progress.bean }}</span>/{{ item.rule.recharge_bean }}</div>
								</div>
								<div class="progress">
									<div class="inner" :style="`width: ${item.progress.bean / item.rule.recharge_bean * 100}%;`"></div>
								</div>
								<div class="time">活动时间：{{ item.start_time }}至{{ item.end_time }}</div>
								<div class="day" v-if="item.rule.total_days">
									<img src="~@/assets/img/welfare-recharge-day.png" alt="">
									充值天数：{{ item.progress.days }}天
								</div>
								<div class="award">
									<img src="~@/assets/img/welfare-recharge-award.png" alt="">
									{{ item.rule.award_desc }}
									<!-- 奖励：{{ item.rule.award_bean_start==item.rule.award_bean_end ? item.rule.award_bean_start : (item.rule.award_bean_start+'-'+item.rule.award_bean_end) }}平台币 -->
								</div>
								<div class="receive-btn" :class="{'disabled': item.progress.finish}" @click="$emit('receive', item.id)">{{ item.progress.finish ? '已领取' : '领取' }}</div>
							</div>
						</div>
					</div>
				</el-scrollbar>
			</div>
		</template>
	</div>
	
</template>

<script>
	import { Welfare } from '@/network/api.js'
	import {mapState} from 'vuex';
	export default{
		props: {
			list: {
				type: Array,
			},
			info:{
				type: Object,
			}
		},
		name:'WelfareRecharge',
		data(){
			return{
				navType: 1,
				navList: [
					{label: '新人充值',value: 1,show:false},
					{label: '充值有礼',value: 2,show:false}
				],
				ruleShow: false
			}
		},
		watch:{
			list:{
				immediate:true,
				deep: true,
				handler(val){
					let newList = val.filter(item=>item.type==1&&item.rule.type==2)
					let rechargeList = val.filter(item=>item.type==1&&item.rule.type==2)
					if(newList.length==0 && this.navType != 2){
						this.navType = 2
					}
					if(newList.length>0){
						this.navList[0].show = true
					}
					if(rechargeList.length>0){
						this.navList[1].show = true
					}
				}
			},
		},
		computed:{
			newList(){
				return this.list.filter(item=>item.type==1&&item.rule.type==2)
			},
			rechargeList(){
				return this.list.filter(item=>item.type==1&&item.rule.type==1)
			},
			currentList(){
				return this.navType == 1 ? this.newList : this.rechargeList
			},
			...mapState([
				'publicConfig'
			])
		},
		created() {
			
		},
		methods:{
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.welfare-main-con{
		height: 100%;
		display: flex;
		flex-direction: column;
		.welfare-scroll-con{
			flex: 1;
			overflow: hidden;
		}
	}
	.welfare-btn{
		height: 58px;
		margin: 8px auto 0;
		@include Hcenter;
		cursor: pointer;
		img{
			height: 100%;
		}
	}
	.welfare-rule-con{
		.title{
			background-image: url(~@/assets/img/welfare-hot-title-bg.png);
			background-size: 100% auto;
			background-repeat: no-repeat;
			background-position: 0 10px;
			text-align: center;
			height: 42px;
			font-size: 20px;
			line-height: 25px;
			padding-top: 3px;
			font-weight: bold;
			// font-style: italic;
		}
		.content{
			width: 328px;
			height: 385px;
			padding: 30px 30px 36px;
			background: url(~@/assets/img/welfare-rule-bg.png);
			background-size: 100% 100%;
			margin-top: 10px;
			.scroll-con{
				height: 100%;
				.el-scrollbar__wrap{
					overflow-x: hidden;
					.word-content{
						font-size: 12px;
						white-space: pre-wrap;
					}
				}
			}
		}
	}
	.welfare-nav-con{
		display: flex;
		gap: 15px;
		flex-shrink: 0;
		width: 343px;
		margin: 0 auto 13px;
		.item{
			line-height: 20px;
			height: 27px;
			cursor: pointer;
			&.active{
				position: relative;
				&::before{
					content: '';
					position: absolute;
					left: 0;
					width: 100%;
					height: 2px;
					bottom: 0;
					background: var(--logo, linear-gradient(180deg, #448EFD 0%, #44FDE7 100%));
				}
			}
		}
	}
	.welfare-recharge-con{
		width: 343px;
		margin: 0 auto;
		
		.total-con{
			border: 1px solid #366981;
			background: #0E0F13;
			@include Hcenter;
			flex-direction: column;
			position: relative;
			gap: 7px;
			padding: 12px 15px;
			margin-bottom: 10px;
			&::before{
				content: '';
				position: absolute;
				left: 0;
				top: 0;
				width: 3px;
				height: 3px;
				background: #fff;
			}
			&::after{
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				width: 3px;
				height: 3px;
				background: #fff;
			}
			.rule-tit{
				@include Scenter;
				color: #A5A5A5;
				position: absolute;
				right: 5px;
				top: 4px;
				font-size: 12px;
				cursor: pointer;
				img{
					width: 14px;
					height: 14px;
					margin-left: 4px;
				}
			}
			.item{
				@include Scenter;
				font-size: 12px;
				.price{
					@include Scenter;
					// margin-left: 30px;
					font-size: 12px;
					.coins{
						width: 14px;
						height: 14px;
						margin: 0 4px;
					}
					span{
						font-weight: bold;
						font-size: 14px;
					}
				}
			}
		}
		.list{
			.item{
				padding: 10px 15px;
				height: 138px;
				border: 1px solid #13709C;
				margin-bottom: 10px;
				background: url(~@/assets/img/welfare-recharge-item-bg.png);
				background-size: 100% 100%;
				@include Hcenter;
				flex-direction: column;
				gap: 8px;
				position: relative;
				.title{
					@include Scenter;
					justify-content: space-between;
					.name{
						width: 220px;
						@include wordhide;
						font-size: 16px;
					}
					.num{
						font-weight: bold;
						span{
							color: #FF3B3B;
						}
					}
				}
				.progress{
					height: 5px;
					background: #454545;
					overflow: hidden;
					.inner{
						background: linear-gradient(100deg, #F6A25A -0.48%, #FEF69E 84.99%);
						height: 100%;
					}
				}
				.time{
					font-size: 12px;
					white-space: nowrap;
					color: #bbb;
				}
				.desc{
					font-size: 12px;
				}
				.day{
					@include Scenter;
					font-size: 12px;
					img{
						width: 20px;
						height: 20px;
						margin-right: 2px;
					}
				}
				.award{
					@include Scenter;
					font-size: 12px;
					img{
						width: 20px;
						height: 20px;
						margin-right: 2px;
					}
				}
				.receive-btn{
					position: absolute;
					right: 14px;
					bottom: 12px;
					border: 1px solid #FFB800;
					background: linear-gradient(180deg, #D33434 0%, #EA5837 100%);
					@include HScenter;
					width: 74px;
					height: 32px;
					cursor: pointer;
					&.finish{
						background: #C35A45;
						border-color: #C35A45;
						color: #fff;
					}
					&.disabled{
						background: none;
						border-color: transparent;
						color: #999;
					}
				}
			}
		}
	}
	
</style>
