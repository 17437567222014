<template>
	<div class="welfare-main-con">
		<div class="welfare-rule-con" v-if="ruleShow">
			<div class="title">活动规则</div>
			<div class="content">
				<div class="scroll-con">
					<el-scrollbar style="height:100%;">
						<div class="word-content">{{ navType==1?publicConfig.desc_activity_sign_in:publicConfig.desc_activity_play }}</div>
					</el-scrollbar>
				</div>
			</div>
			<div class="welfare-btn"><img @click="ruleShow=false" src="~@/assets/img/welfare/return-btn-bg.png" alt=""></div>
		</div>
		<template v-else>
			<div class="welfare-nav-con">
				<div v-for="(item,index) in navList" :key="index" class="item" :class="{'active': navType==item.value}" @click="navType=item.value" :style="`display: ${item.show?'block':'none'}`">{{ item.label }}</div>
			</div>
			<div class="welfare-scroll-con">
				<el-scrollbar style="height:100%;">
					<div class="welfare-sign-con" v-if="navType==1">
						<div class="welfare-sign-banner">
							活动时间：{{ info.sign_scope }}
						</div>
						<div class="welfare-sign-list">
							<div class="item" :class="{'received': item.progress.finish}" @click="$emit('receive',item.id)" v-for="(item,index) in currentList" :key="item.id">
								<div class="img">
									<div class="num">
										<img v-for="(item1,index1) in item.numArr" :key="index1" :src="numImg[item1]" alt="">
									</div>
									<img v-if="item.progress.finish" src="~@/assets/img/welfare-sign-received.png" alt="" class="word">
									<img v-else src="~@/assets/img/welfare-sign-receive.png" alt="" class="word">
								</div>
								<div class="name">累计签到{{ item.rule.sign_in_days }}天</div>
							</div>
						</div>
						<div class="welfare-total-sign-con">
							<div class="welfare-total-sign">
								本月累计签到{{ info.sign_month }}天
							</div>
							<div class="welfare-btn" @click="$emit('sign')"><img src="~@/assets/img/welfare/sign-btn-bg.png" alt=""></div>
							<div class="rule-tit" @click="ruleShow=true">
								规则说明
								<img src="~@/assets/img/icon-help.png" alt="">
							</div>
						</div>
					</div>
					
					<div class="welfare-recharge-con" v-else>
						<div class="total-con">
							<div class="rule-tit" @click="ruleShow=true">规则说明<img src="~@/assets/img/icon-help.png" alt=""></div>
							<div class="item">{{ navLabel }}累计领取平台币：{{ currentBean }}</div>
						</div>
						<div class="list">
							<div class="item" v-for="(item,index) in currentList" :key="index">
								<div class="title">
									<div class="name">{{ item.title }}</div>
									<div class="num"><span>{{ item.progress.bean }}</span>/{{ item.rule.use_bean }}</div>
								</div>
								<div class="progress">
									<div class="inner" :style="`width: ${item.progress.bean / item.rule.use_bean * 100}%;`"></div>
								</div>
								<div class="time">活动时间：{{ item.start_time }}至{{ item.end_time }}</div>
								<!-- <div class="desc">{{ item.rule.award_desc }}</div> -->
								<div class="award">
									<img src="~@/assets/img/welfare-recharge-award.png" alt="">
									{{ item.rule.award_desc }}
									<!-- 奖励：{{ item.rule.award_bean_start==item.rule.award_bean_end ? item.rule.award_bean_start : (item.rule.award_bean_start+'-'+item.rule.award_bean_end) }}平台币 -->
								</div>
								<div class="receive-btn finish" v-if="!item.progress.achieve" :class="[item.progress.finish?'disabled':'']" @click="toFinish(item)">去完成</div>
								<div class="receive-btn" v-else :class="[item.progress.finish?'disabled':'']" @click="$emit('receive', item.id)">{{ item.progress.finish ? '已领取' : '领取' }}</div>
							</div>
						</div>
					</div>
					<!-- <div class="welfare-sign-banner">
						活动时间：2022-10-10  12:00:00 至 2022-10-10  12:00:00
					</div>
					<div class="box-list">
						<div class="item" v-for="(item1,index1) in item.arr" :key="index1" :class="{'received':item1.receive}" @click="item1.receive ? '' : signReceive(item1.id)">
							<div class="img-con">
								<div class="num">
									<img v-for="(item2,index2) in item1.numArr" :key="index2" :src="numImg[item2]" alt="">
								</div>
								<img src="~@/assets/image/welfare-sign-received.png" alt="" class="word" v-if="item1.receive">
								<img src="~@/assets/image/welfare-sign-receive.png" alt="" class="word" v-else>
							</div>
							<div class="name">累计签到{{item1.signDays}}天奖励</div>
						</div>
					</div>
					<div class="welfare-total-sign">
						本月累计签到{{item.obj.signDays}}天
					</div>
					<div class="big-btn" :class="{'received':item.obj.sign}" @click="item.obj.sign ? '' : welfareSign()"><span>{{item.obj.sign ? '已签到' : '签到'}}</span></div>
					<div class="intro-btn" @click="item.openRule=true">
						规则说明
						<img src="~@/assets/image/icon-welfare-wh.png" alt="">
					</div> -->
				</el-scrollbar>
			</div>
		</template>
	</div>
</template>

<script>
	import { Welfare } from '@/network/api.js'
	import {mapState} from 'vuex'
	export default{
		name:'WelfareMession',
		props: {
			list: {
				type: Array,
			},
			info:{
				type: Object,
			}
		},
		data(){
			return{
				navType: 1,
				navList: [
					{label: '每日签到',value: 1, show: false},
					// {label: '盲盒开箱',value: 2, show: false},
					{label: '盲盒对战',value: 3, show: false},
					{label: '汰换合约',value: 4, show: false}
				],
				numImg:[
					require('@/assets/img/welfare/0.png'),
					require('@/assets/img/welfare/1.png'),
					require('@/assets/img/welfare/2.png'),
					require('@/assets/img/welfare/3.png'),
					require('@/assets/img/welfare/4.png'),
					require('@/assets/img/welfare/5.png'),
					require('@/assets/img/welfare/6.png'),
					require('@/assets/img/welfare/7.png'),
					require('@/assets/img/welfare/8.png'),
					require('@/assets/img/welfare/9.png')
				],
				ruleShow: false
			}
		},
		computed:{
			currentBean(){
				return this.navType == 1 ? this.info.act_data.bean_type_66 : 
					this.navType == 2 ? this.info.act_data.bean_type_63 : 
					this.navType == 3 ? this.info.act_data.bean_type_64 : 
					this.navType == 4 ? this.info.act_data.bean_type_65 : ''
			},
			navLabel(){
				return this.navList.find(item=>item.value==this.navType).label
			},
			signList(){
				return this.list.filter(item=>item.type==3)
			},
			boxList(){
				return this.list.filter(item=>item.type==2&&item.rule.type==1)
			},
			battleList(){
				return this.list.filter(item=>item.type==2&&item.rule.type==2)
			},
			replaceList(){
				return this.list.filter(item=>item.type==2&&item.rule.type==3)
			},
			currentList(){
				return this.navType == 1 ? this.signList : 
					this.navType == 2 ? this.boxList : 
					this.navType == 3 ? this.battleList : 
					this.navType == 4 ? this.replaceList : []
			},
			...mapState(['publicConfig'])
		},
		created() {
			console.log(222)
		},
		watch:{
			list:{
				immediate:true,
				deep: true,
				handler(val){
					let signList = val.filter(item=>item.type==3)
					let boxList = val.filter(item=>item.type==2&&item.rule.type==1)
					let battleList = val.filter(item=>item.type==2&&item.rule.type==2)
					let replaceList = val.filter(item=>item.type==2&&item.rule.type==3)
					
					if(signList.length>0){
						this.navList[0].show = true
					}
					if(boxList.length>0){
						this.navList[1].show = true
					}
					if(battleList.length>0){
						this.navList[2].show = true
					}
					if(replaceList.length>0){
						this.navList[3].show = true
					}
					
					if(this.navType==1){
						let navType = 1
						if(signList.length==0){
							navType = 2
							if(boxList.length==0){
								navType = 3
								if(battleList){
									navType = 4
								}
							}
						}
						this.navType = navType
					}
				}
			},
		},
		methods:{
			toFinish(item){
				if(item.rule.type==1){
					this.$router.push('/Home')
				}else if(item.rule.type==2){
					// this.$router.push('/Battle')
				}else if(item.rule.type==3){
					this.$router.push('/Replacement')
				}
			}
		}
	}
</script>

<style lang="scss">
	@import "@/assets/css/base.scss";
	.welfare-sign-banner{
		text-align: center;
		width: 354px;
		height: 114px;
		background: url(~@/assets/img/welfare-sign-banner.png);
		background-size: 100% 100%;
		font-size: 12px;
		padding-top: 89px;
		margin-top: 18px;
	}
	.welfare-sign-list{
		display: flex;
		justify-content: center;
		margin-top: 18px;
		.item{
			cursor: pointer;
			&.received{
				filter: grayscale(0.5);
				cursor: auto;
			}
			.img{
				background-image: url(~@/assets/img/welfare-sign-bg.png);
				background-size: 100% 100%;
				width: 120px;
				height: 96px;
				padding-top: 20px;
				@include Scenter;
				flex-direction: column;
				.num{
					width: 70px;
					height: 30px;
					@include HScenter;
					margin-right: 10px;
					img{
						height: 100%;
						flex-shrink: 0;
					}
				}
				.word{
					height: 24px;
					display: block;
					margin-right: 10px;
					margin-top: 6px;
				}
			}
			.name{
				font-family: 'ZhenyanGB';
				margin-top: 6px;
				text-align: center;
				color: #E9BBFF;
				font-weight: bold;
			}
		}
	}
	.welfare-total-sign-con{
		@include Scenter;
		flex-direction: column;
		margin-top: 30px;
		.welfare-total-sign{
			font-size: 26px;
			line-height: 40px;
			color: #E9BBFF;
			font-weight: bold;
		}
		.rule-tit{
			@include Scenter;
			width: 88px;
			height: 24px;
			font-size: 12px;
			color: #A5A5A5;
			cursor: pointer;
			img{
				width: 14px;
				height: 14px;
				margin-left: 4px;
			}
		}
	}
</style>
